/* Header = logo + header-menu */
.Header{
    display: flex;
    justify-content: space-between;
}

.logo{
    width: 8rem;
    height: 8rem;
}

.Header-Menu{
    list-style: none;
    display: flex;
    gap: 2rem;
    color: rgb(231, 238, 255);
    margin-right: 5rem;
}

.Header-Menu>li:hover{
    color: orange;
    cursor: pointer;
    
}
